// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProjectActionButtons__actionButtonsContainer__kpf_6";
export var column = "ProjectActionButtons__column__r1bOP";
export var finalizedContainer = "ProjectActionButtons__finalizedContainer__mSZ0t";
export var finalizedText = "ProjectActionButtons__finalizedText__hikNn";
export var headerButton = "ProjectActionButtons__headerButton__UKTI6";
export var inactiveText = "ProjectActionButtons__inactiveText__U5Kpv";
export var modalMessageButtons = "ProjectActionButtons__modalMessageButtons__s8vdI";
export var modalMessageContainer = "ProjectActionButtons__modalMessageContainer__M48ym";
export var modalMessageOverlay = "ProjectActionButtons__modalMessageOverlay__azOl3";
export var modalMessageText = "ProjectActionButtons__modalMessageText__GMwDW";
export var noMarginRight = "ProjectActionButtons__noMarginRight__jYOob";
export var popOverBodyWrapper = "ProjectActionButtons__popOverBodyWrapper__MFcFx";
export var popOverMenuText = "ProjectActionButtons__popOverMenuText__AjQrD";
export var popoverBody = "ProjectActionButtons__popoverBody__grwhp";
export var popoverButton = "ProjectActionButtons__popoverButton__rfceI";
export var popoverRow = "ProjectActionButtons__popoverRow__Mx9MI";
export var popoverTrigger = "ProjectActionButtons__popoverTrigger__qtLi5";
export var popoverWrapper = "ProjectActionButtons__popoverWrapper__Axsqu";
export var reviewContainer = "ProjectActionButtons__reviewContainer__r5LZk";
export var reviewText = "ProjectActionButtons__reviewText__S1g_z";
export var row = "ProjectActionButtons__row__ajV0i";
export var statusPopoverRow = "ProjectActionButtons__statusPopoverRow__BEAq5";
export var toggleContainer = "ProjectActionButtons__toggleContainer__Sruli";